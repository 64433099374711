import _ from "lodash/fp";
import moment from "moment";
import { zeros } from "scores/constants";

const isNilOrEmpty = (value) =>
  _.isNil(value) || _.isNaN(value) || value === "";
const isValidNumber = _.flow(_.toNumber, _.negate(_.isNaN));

const validationErrors = (fields, value) => {
  const missingRequired = _.flow(
    _.filter("required"),
    _.map("field"),
    _.filter((field) => isNilOrEmpty(_.prop(field)(value))),
    _.map((field) => [field, "Campo obligatório"]),
    _.fromPairs
  )(fields);

  const invalidNumberFormat = _.flow(
    _.filter(_.matches({ type: "numeric" })),
    _.map("field"),
    _.reject((field) => {
      const fieldValue = _.prop(field)(value);
      return isNilOrEmpty(fieldValue) || isValidNumber(fieldValue);
    }),
    _.map((field) => [field, "Formato numérico inválido"]),
    _.fromPairs
  )(fields);

  const invalidPointsFormat = _.flow(
    _.filter(_.matches({ type: "points" })),
    _.map("field"),
    _.reject((field) =>
      _.flow(
        _.prop(field),
        _.toPairs,
        _.every(([_k, point]) => isValidNumber(point))
      )(value)
    ),
    _.map((field) => [field, "Puntos inválidos"]),
    _.fromPairs
  )(fields);
  return { ...invalidNumberFormat, ...missingRequired, ...invalidPointsFormat };
};

const formatField = (type, value) => {
  switch (type) {
    case "numeric":
      const parsedValue = _.toNumber(value);
      return _.isNaN(parsedValue) ? null : parsedValue;
    case "users":
    case "learning_object":
      return _.map(({ id }) => id)(value);
    case "date":
      return value;
    case "points":
      return {
        ...zeros,
        ..._.flow(
          _.toPairs,
          _.map(([k, v]) => [k, _.toNumber(v)]),
          _.fromPairs
        )(value),
      };
    default:
      return value;
  }
};
const formatValue = (fields, value) =>
  _.reduce(
    (newItem, { field, type }) =>
      _.set(field, formatField(type, _.prop(field)(value)))(newItem),
    {}
  )(fields);

export { validationErrors, formatValue };
