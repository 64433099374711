const ACTIONS = "/api/v1/actions";
const ACTION = "/api/v1/actions/:id";
const USER_ACTIONS = "/api/v1/users/:id/actions";
const USER_ACTION = "/api/v1/user_actions/:id";
const LEARNING_OBJECT_ACTION = "/api/v1/learning_objects/:lo_id/actions";
const SEASONS = "/api/v1/seasons";
const SEASON = "/api/v1/seasons/:id";
const ACTIVATE_SEASON = "/api/v1/seasons/:id/activate";
export {
  ACTIONS,
  ACTION,
  USER_ACTIONS,
  USER_ACTION,
  LEARNING_OBJECT_ACTION,
  SEASONS,
  SEASON,
  ACTIVATE_SEASON,
};
