import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Check as CheckIcon } from "@material-ui/icons";
import OrderedTable from "common/OrderedTable";
import { activateSeason } from "../routines";
import SeasonForm from "./SeasonForm";

const useStyles = makeStyles({
  createButton: {
    float: "right",
    margin: "15px",
  },
  actions: {
    display: "flex",
    gap: "10px",
  },
});

export default function Seasons() {
  const [selectedSeason, setSelectedSeason] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { seasons, fetchingSeasons } = useSelector(
    _.pick(["seasons", "fetchingSeasons"])
  );

  const onActivateSeason = (season) => {
    dispatch(activateSeason(season));
  };

  const columns = [
    // {
    //   id: "id",
    //   label: "",
    //   render: (userActionId) => (
    //     <>
    //       {expireAction && (
    //         <ExpireUserActionButton userActionId={userActionId} />
    //       )}
    //     </>
    //   ),
    // },

    {
      id: "name",
      label: "Nombre",
    },
    {
      id: "active",
      label: "Activo",
      render: (active) => (active ? <CheckIcon /> : null),
    },
    {
      id: "start_date",
      label: "Fecha inicio",
    },
    {
      id: "end_date",
      label: "Fecha fin",
    },
    {
      id: "set_active",
      render: (_k, season) => (
        <div className={classes.actions}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setSelectedSeason(season)}
          >
            Editar
          </Button>
          {season.active ? null : (
            <Button
              variant="outlined"
              size="small"
              onClick={() => onActivateSeason(season)}
            >
              Activar
            </Button>
          )}
        </div>
      ),
    },
  ];

  const searchFields = ["name"];

  return (
    <>
      {!selectedSeason && (
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={() => setSelectedSeason({})}
        >
          Crear nueva season
        </Button>
      )}
      <h1>Seasons</h1>
      {selectedSeason && (
        <SeasonForm
          season={selectedSeason}
          onSubmit={() => setSelectedSeason(null)}
        />
      )}

      {fetchingSeasons ? null : (
        <OrderedTable
          columns={columns}
          content={seasons}
          searchFields={searchFields}
        />
      )}
    </>
  );
}
