import { userActionsRequestSaga } from "./userActionsSaga";
import { actionsRequestSaga } from "./actionsSaga";
import { seasonsRequestSaga } from "./seasonsSaga";

export { userActionsRequestSaga, actionsRequestSaga, seasonsRequestSaga };
export default [
  userActionsRequestSaga(),
  actionsRequestSaga(),
  seasonsRequestSaga(),
];
