import { apiGet, apiPatch, apiPost, JSON_OPTS } from "api";
import pathToRegexp from "path-to-regexp";
import { SEASONS, SEASON, ACTIVATE_SEASON } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSeasons,
  fetchActiveSeason,
  createSeason,
  updateSeason,
  activateSeason,
} from "../routines";

export function* seasonsRequestSaga() {
  yield takeLatest(fetchSeasons.TRIGGER, fetchSeasonsSaga);
  yield takeLatest(fetchActiveSeason.TRIGGER, fetchActiveSeasonSaga);
  yield takeLatest(createSeason.TRIGGER, createSeasonSaga);
  yield takeLatest(activateSeason.TRIGGER, activateSeasonSaga);
  yield takeLatest(updateSeason.TRIGGER, updateSeasonSaga);
}

export function* fetchSeasonsSaga() {
  try {
    const url = SEASONS;
    yield put(fetchSeasons.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchSeasons.success(data));
  } catch (error) {
    yield put(fetchSeasons.failure(error.response));
  } finally {
    yield put(fetchSeasons.fulfill());
  }
}

export function* fetchActiveSeasonSaga() {
  try {
    const url = pathToRegexp.compile(SEASON)({ id: "active" });
    yield put(fetchActiveSeason.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchActiveSeason.success(data));
  } catch (error) {
    yield put(fetchActiveSeason.failure(error.response));
  } finally {
    yield put(fetchActiveSeason.fulfill());
  }
}

export function* createSeasonSaga({ payload }) {
  try {
    yield put(createSeason.request());
    const { data } = yield call(
      apiPost,
      SEASONS,
      { season: payload },
      JSON_OPTS
    );
    yield put(createSeason.success(data));
    yield put(fetchSeasons.trigger());
  } catch (error) {
    yield put(createSeason.failure(error.response));
  } finally {
    yield put(createSeason.fulfill());
  }
}
export function* updateSeasonSaga({ payload }) {
  try {
    const { season, id } = payload;
    const url = pathToRegexp.compile(SEASON)({ id });
    yield put(updateSeason.request());
    const { data } = yield call(apiPatch, url, { season }, JSON_OPTS);
    yield put(updateSeason.success(data));
    yield put(fetchSeasons.trigger());
  } catch (error) {
    yield put(updateSeason.failure(error.response));
  } finally {
    yield put(updateSeason.fulfill());
  }
}

export function* activateSeasonSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(ACTIVATE_SEASON)(payload);
    yield put(activateSeason.request());
    const { data } = yield call(apiPatch, url, {}, JSON_OPTS);
    yield put(activateSeason.success(data));
    yield put(fetchSeasons.trigger());
  } catch (error) {
    yield put(activateSeason.failure(error.response));
  } finally {
    yield put(activateSeason.fulfill());
  }
}
