import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSeasons } from "../routines";

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSeasons());
  }, [dispatch]);
  return null;
};
