import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import {
  AllUserActionsLoader,
  GenericActionsLoader,
  InProgressActions,
  UserPendingActions,
  Rewards,
  Payments,
  UserActionsLatest,
  ActiveSeason,
  ActiveSeasonLoader,
  Seasons,
  SeasonsLoader,
} from ".";
import { UsersLoader } from "accounts/components";

export const ScoresRouter = () => (
  <>
    <Switch>
      <Route
        exact
        path={routes.USER_ACTIONS_IN_PROGRESS}
        render={() => (
          <>
            <AllUserActionsLoader
              actionTypes={["request", "complete", "out_complete"]}
            />
            <InProgressActions />
          </>
        )}
      />
      <Route
        exact
        path={routes.USER_ACTIONS_LASTEST}
        render={() => (
          <>
            <AllUserActionsLoader
              actionTypes={["request", "complete", "out_complete"]}
            />
            <UserActionsLatest />
          </>
        )}
      />
      <Route
        exact
        path={routes.USER_ACTIONS_PAYMENTS}
        render={() => (
          <>
            <AllUserActionsLoader actionTypes={["request", "complete"]} />
            <Payments />
          </>
        )}
      />
      <Route
        exact
        path={routes.USER_ACTIONS}
        render={() => (
          <>
            <AllUserActionsLoader pending={true} />
            <UserPendingActions />
          </>
        )}
      />
      <Route
        exact
        path={routes.REWARDS}
        render={() => (
          <>
            <UsersLoader />
            <GenericActionsLoader />
            <Rewards />
          </>
        )}
      />
      <Route
        exact
        path={routes.ACTIVE_SEASON}
        render={() => (
          <>
            <ActiveSeasonLoader />
            <ActiveSeason />
          </>
        )}
      />
      <Route
        exact
        path={routes.SEASONS}
        render={() => (
          <>
            <SeasonsLoader />
            <Seasons />
          </>
        )}
      />
    </Switch>
  </>
);

export default ScoresRouter;
