import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import GenericForm from "common/GenericForm";
import { createSeason, updateSeason } from "../routines";

const useStyles = makeStyles({
  form: {
    margin: "15px 0px",
    padding: "20px",
  },
});

const formFields = [
  { field: "name", label: "Nombre", required: true },
  {
    field: "start_date",
    label: "Fecha inicio (inclusive)",
    type: "date",
    required: true,
  },
  {
    field: "end_date",
    label: "Fecha fin (exclusive)",
    type: "date",
    required: true,
  },
];

export default ({ season, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    if (season.id) dispatch(updateSeason({ season: data, id: season.id }));
    else dispatch(createSeason(data));
    onSubmit();
  };

  return (
    <Paper className={classes.form}>
      <GenericForm
        defaultItem={season}
        fields={formFields}
        onSubmit={handleSubmit}
        onCancel={onSubmit}
        submitText={season.id ? "Editar season" : "Crear season"}
      />
    </Paper>
  );
};
