import _ from "lodash/fp";
import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
  Avatar,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { ImportContacts as ImportContactsIcon } from "@material-ui/icons";

import { UserCompact } from "accounts/components";
const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-around",
    margin: "auto",
    maxWidth: "600px",
    paddingBottom: "50px",
  },
  primero: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarPrimero: {
    width: "100px",
    height: "100px",
  },
  nombrePrimero: {
    fontSize: "1.3rem",
  },
  puntosPrimero: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  segundo: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarSegundo: {
    width: "75px",
    height: "75px",
  },
  nombreSegundo: {
    fontSize: "1.2rem",
  },
  puntosSegundo: {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  tercero: {
    marginTop: "175px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarTercero: {
    width: "50px",
    height: "50px",
  },
  nombreTercero: {
    fontSize: "1.1rem",
  },
  puntosTercero: {
    fontSize: "1.0rem",
    fontWeight: "bold",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  titleContainer: {
    paddingLeft: "25px",
    paddingRight: "25px",
    display: "flex",
    flexDirection: "column",
  },
  alertContainer: {
    position: "absolute",
    right: "15px",
    top: "80px",
  },
  chip: {
    marginLeft: "5px",
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
  },
  myRank: {
    display: "flex",
    flexDirection: "column",
    margin: "20px",
    padding: "10px",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  myRankNumber: {
    fontSize: "1.5rem",
  },

  alertTitle: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alertContent: {
    display: "flex",
    alignItems: "center",
  },
  actionTimestamp: {
    fontWeight: "bold",
    marginLeft: "5px",
  },

  rankList: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  rankItem: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    border: "1px solid gray",
    borderRadius: "5px",
  },

  rankField: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  rankFieldTitle: {
    fontWeight: "bold",
  },
  rankFieldContent: {
    fontSize: "1.2rem",
  },
  tableContainer: { padding: "25px" },
  fitContent: {
    margin: "auto",
    width: "fit-content",
    minWidth: "500px",
  },
});

export default function ActiveSeason() {
  const classes = useStyles();
  const { activeSeason, fetchingActiveSeason } = useSelector(
    _.pick(["activeSeason", "fetchingActiveSeason"])
  );
  const user1 = _.nth(0)(activeSeason?.ranking);
  const user2 = _.nth(1)(activeSeason?.ranking);
  const user3 = _.nth(2)(activeSeason?.ranking);

  return fetchingActiveSeason ? (
    <h3>Cargando season...</h3>
  ) : _.isNil(activeSeason?.season) ? (
    <h2>No hay ninguna season activa.</h2>
  ) : (
    <>
      <Paper className={classes.header}>
        <SeasonSummary season={activeSeason?.season} />
        <CurrentUser currentUser={activeSeason?.currentUser} />
      </Paper>
      <Paper className={classes.container}>
        <User2 rank={user2} />
        <User1 rank={user1} />
        <User3 rank={user3} />
      </Paper>
      <Ranking ranking={activeSeason?.ranking} />
    </>
  );
}

function SeasonSummary({ season }) {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      <h1>{season.name}</h1>
      <h2>
        De {moment(season.start_date).format("DD/MM/YYYY")} a{" "}
        {moment(season.end_date).format("DD/MM/YYYY")}
      </h2>
    </div>
  );
}

function CurrentUser({ currentUser }) {
  const classes = useStyles();
  return currentUser ? (
    <div className={classes.alertContainer}>
      <Alert severity="info">
        <AlertTitle className={classes.alertTitle}>
          Tu rango es{" "}
          <span className={classes.myRankNumber}>#{currentUser.position}</span>
        </AlertTitle>
        <p className={classes.alertContent}>
          Tienes
          <Chip
            className={classes.chip}
            size="small"
            variant="outlined"
            icon={<ImportContactsIcon />}
            label={currentUser.learning_points}
          />
          puntos.
        </p>
      </Alert>
    </div>
  ) : (
    <div className={classes.alertContainer}>
      <Alert severity="warning">
        <AlertTitle className={classes.alertTitle}>
          Aún no tienes puntos en esta season
        </AlertTitle>
        <p className={classes.alertContent}>
          Gana puntos de
          <Chip
            className={classes.chip}
            size="small"
            variant="outlined"
            icon={<ImportContactsIcon />}
            label="Aprendizaje"
          />
          para participar.
        </p>
      </Alert>
    </div>
  );
}

function User1({ rank }) {
  const classes = useStyles();
  if (!rank) return <div></div>;
  const { user, learning_points } = rank;
  return user ? (
    <div className={classes.primero}>
      <Avatar src={user.picture} className={classes.avatarPrimero} />
      <span className={classes.nombrePrimero}>#1 {user.given_name}</span>
      <span className={classes.puntosPrimero}>{learning_points}</span>
    </div>
  ) : null;
}
function User2({ rank }) {
  const classes = useStyles();
  if (!rank) return <div></div>;
  const { user, learning_points } = rank;
  return user ? (
    <div className={classes.segundo}>
      <Avatar src={user.picture} className={classes.avatarSegundo} />
      <span className={classes.nombreSegundo}>#2 {user.given_name}</span>
      <span className={classes.puntosSegundo}>{learning_points}</span>
    </div>
  ) : null;
}
function User3({ rank }) {
  const classes = useStyles();
  if (!rank) return <div></div>;
  const { user, learning_points } = rank;
  return user ? (
    <div className={classes.tercero}>
      <Avatar src={user.picture} className={classes.avatarTercero} />
      <span className={classes.nombreTercero}>#3 {user.given_name}</span>
      <span className={classes.puntosTercero}>{learning_points}</span>
    </div>
  ) : null;
}

function Ranking({ ranking }) {
  const classes = useStyles();
  if (_.isEmpty(ranking)) return null;
  return (
    <div className={classes.tableContainer}>
      <TableContainer className={classes.fitContent} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell align="center">Puntos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ranking.map(({ user, position, learning_points }) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row" align="center">
                  {position}
                </TableCell>
                <TableCell>
                  <UserCompact user={user} />
                </TableCell>
                <TableCell align="center">{learning_points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
