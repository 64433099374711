import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchActiveSeason } from "../routines";

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchActiveSeason());
  }, [dispatch]);
  return null;
};
