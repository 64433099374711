import _ from "lodash/fp";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserAction } from "scores/routines";

import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import ButtonModal from "common/ButtonModal";
import LearningObjectCompact from "learning_objects/components/LearningObjectCompact";
import ActionTypeChip from "../ActionTypeChip";
import PointsCompact from "../PointsCompact";
import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";
import { getActionFields } from "./userActionFields";

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerName: {
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "10px",
    marginRight: "10px",
  },
  detail: {
    padding: "20px",
    fontSize: "16px",
  },
  completeProofButton: {
    marginLeft: "5px",
    textTransform: "none",
  },
});

export default ({ userAction, user, fetchActionTypes }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState(userAction);
  const [formErrors, setFormErrors] = useState();

  const learningObject = _.prop("action.learning_object")(userAction);
  const actionType = _.prop("action.action_type")(userAction);
  const action = _.prop("action")(userAction);
  const fields = getActionFields(actionType);

  const onApprove = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    const { action_timestamp: newActionTs, metadata } = formatValue(
      fields,
      form
    );
    const newMetadata = {
      ..._.prop("metadata")(userAction),
      ...metadata,
    };

    const actionTime = _.flow(
      _.prop("action_timestamp"),
      _.split("T"),
      _.nth(1),
      _.defaultTo("00:00:00Z")
    )(form);
    const actionDate = _.flow(_.split("T"), _.nth(0))(newActionTs);
    const action_timestamp = `${actionDate}T${actionTime}`;

    dispatch(
      updateUserAction({
        user,
        fetchActionTypes,
        user_action: {
          id: _.get("id")(userAction),
          metadata: newMetadata,
          action_timestamp,
        },
        refreshUserActions: !_.isNil(user),
      })
    );
  };

  return (
    <ButtonModal
      buttonIcon={<EditIcon />}
      modalTitle="Modificar solicitud"
      onSubmit={onApprove}
    >
      <>
        <div className={classes.header}>
          <ActionTypeChip action_type={actionType} />
          <LearningObjectCompact learningObject={learningObject} tooltip />
          <PointsCompact points={action} />
        </div>
        <div className={classes.detail}>
          <FormBody
            value={form}
            setValue={setForm}
            fields={fields}
            errors={formErrors}
          />
        </div>
      </>
    </ButtonModal>
  );
};
