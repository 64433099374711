import { fetchSeasons, fetchActiveSeason } from "../routines";

const fetchingSeasons = (state = false, { type }) => {
  switch (type) {
    case fetchSeasons.TRIGGER:
      return true;
    case fetchSeasons.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const seasons = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchSeasons.SUCCESS:
      return payload.data;
    default:
      return state;
  }
};

const fetchingActiveSeason = (state = false, { type }) => {
  switch (type) {
    case fetchActiveSeason.TRIGGER:
      return true;
    case fetchActiveSeason.FULFILL:
      return false;
    default:
      return state;
  }
};

const activeSeason = (state = null, { type, payload }) => {
  switch (type) {
    case fetchActiveSeason.SUCCESS:
      const {
        data: season,
        _embedded: { ranking, current_user },
      } = payload;
      return { season, ranking, currentUser: current_user };
    default:
      return state;
  }
};

export { seasons, fetchingSeasons, activeSeason, fetchingActiveSeason };
